<template>
  <div class="scrollblank">
    <!-- Footer-->
    <footer class="footer bg-light">
      <div class="container">
        <div class="row">
          <div class="h-100 text-center text-lg-start my-auto">
            <ul class="list-inline mb-2">
              <li class="list-inline-item"><a>About</a></li>
              <li class="list-inline-item">⋅</li>
              <li class="list-inline-item"><a>Contact</a></li>
              <li class="list-inline-item">⋅</li>
              <li class="list-inline-item"><a>Terms of Use</a></li>
              <li class="list-inline-item">⋅</li>
              <li class="list-inline-item"><a>Privacy Policy</a></li>
            </ul>
            <p class="text-muted small mb-4 mb-lg-0">
              Copyright &copy; 2020 B2B코스트. All Rights Reserved.
            </p>
          </div>
          <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
            <!-- <ul class="list-inline mb-0">
              <li class="list-inline-item me-4">
                <a href="#!"><i class="bi-facebook fs-3"></i></a>
              </li>
              <li class="list-inline-item me-4">
                <a href="#!"><i class="bi-twitter fs-3"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#!"><i class="bi-instagram fs-3"></i></a>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="row">
          <div class="h-100 text-center text-lg-start my-auto">
            <ul class="list-inline mb-2">
              <li class="list-inline-item">
                <a>회사명 : B2B코스트 / 대표 : 이주일</a>
              </li>
              <li class="list-inline-item">
                <a
                  >주소 : 부산광역시 부산진구 범전로 31, 신라빌딩 5F</a
                >
              </li>
              <br />
              <li class="list-inline-item">
                <a>사업자 등록번호 : 369-86–01519</a>
              </li>
              <li class="list-inline-item">
                <a>전화 : 1668-0220 팩스 051 - 866 - 6033</a>
              </li>
              <li class="list-inline-item">
                <a>개인정보관리책임자 : 이주일</a>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "FooterCom",
  components: {},
};
</script>

<style>
footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
a {
  text-decoration-line: none;
  color: #707070;
}
</style>
