import axios from "axios";

const hostname = window && window.location && window.location.hostname;

export default axios.create({
    baseURL: hostname === 'localhost' ? "http://localhost:8080/api/auth" : "https://www.b2b-cost.kr/api/auth",
});

// export default axios.create({
//     baseURL: hostname === 'localhost' ? "http://localhost:8000/api/auth" : "https://www.b2b-cost.kr/api/auth",
// });
