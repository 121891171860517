<template>
    <div>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
        <router-link to="/">
            <!-- <a class="navbar-brand js-scroll-trigger" href="#page-top"> -->
                <span class="d-none d-lg-block"><img class="img-fluid" src="@/assets/img/b2blogo.png" alt="..." /></span>
            <!-- </a> -->
        </router-link>
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
            </button> -->

            <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"> </span>
            </button>




<!-- 
            <div class="collapse navbar-collapse" id="navbarToggleExternalContent">
                <div>
                    <ul class="navbar-nav  text-uppercase ms-auto py-4 py-lg-0">
                        <li class="nav-item">
                            <a 
                                class="navbar-brand js-scroll-trigger"
                                href="#page-top">
                                HOME
                            </a>
                        </li>                        

                        <li class="nav-item">
                            <a class="navbar-brand"
                                href="#">
                                회사소개
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-brand"
                                href="#">
                                B2B 란?
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-brand dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                기업렌탈이란?
                            </a>
                            <div class="dropdown">
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" href="#">
                                        상품소개
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        대상제품
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        심사진행
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        승인한도
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-brand dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                B2B 기업렌탈
                            </a>
                            <div class="dropdown">
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" href="#">
                                        심사안내
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        기계할부렌탈
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        구입비교
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
 -->


            <div class="collapse navbar-collapse" id="navbarToggleExternalContent">
                <div id="menu2">
                    <!-- <ul class="navbar-nav  text-uppercase ms-auto py-4 py-lg-0"> -->
                    <ul class="navbar-nav  text-uppercase ms-auto py-4 py-lg-0 M01">
                        <li>
                            <router-link class="navbar-brand" to="/">
                                HOME
                            </router-link>
                            <ul class="navbar-nav M02">
                                <li><a class="navbar-brand" href="#ytb1">기업렌탈 소개</a></li>
                                <li><a class="navbar-brand" href="#ytb2">리스와 렌탈의 차이</a></li>
                            </ul>
                        </li>                        

                        <li>
                            <router-link class="navbar-brand" to="/about">
                                회사소개
                            </router-link>
                        </li>
                        <li>
                            <router-link class="navbar-brand" to="/notice">
                                공지사항
                            </router-link>
                        </li>
                        <li>
                            <router-link class="navbar-brand" to="/b2babout">
                                B2B 란?
                            </router-link>
                        </li>
                        <li><router-link class="navbar-brand" to="/comprent">기업렌탈이란?</router-link>
                            <ul class="navbar-nav M02">
                                <li><router-link class="navbar-brand" to="/introprod">상품소개</router-link></li>
                                <li><router-link class="navbar-brand" to="/targetprod">대상제품</router-link></li>
                                <li><router-link class="navbar-brand" to="/auditproc">심사진행</router-link></li>
                                <li><router-link class="navbar-brand" to="/limit">승인한도</router-link></li>
                            </ul>
                        </li>
                        <li>
                            <router-link class="navbar-brand" to="/b2brent">
                                B2B 기업렌탈
                            </router-link>
                            <ul class="navbar-nav M02">
                                <li><router-link class="navbar-brand" to="/auditguide">심사안내</router-link>
                                    <ul class="navbar-nav M03">
                                        <li><router-link class="navbar-brand" to="/seoulgrtupload">서울보증 재무재표 등록방법</router-link>
                                            <ul class="navbar-nav M04">
                                                <li><a class="navbar-brand" href="https://www.sgic.co.kr/chp/main.mvc">서울보증보험 바로가기</a></li>
                                            </ul>
                                        </li>
                                        <li><router-link class="navbar-brand" to="/oneclicknotice">원클릭 심사서류 이용방법</router-link>
                                            <ul class="navbar-nav M04">
                                                <li><a class="navbar-brand" href="https://www.one-click.co.kr/cm/CM0100M001GE.nice">원클릭 바로가기</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <!-- <li><router-link class="navbar-brand" to="/machinerant">기계할부렌탈</router-link></li> -->
                                <li><router-link class="navbar-brand" to="/purchcomp">구입비교</router-link></li>
                            </ul>

                        </li>
                        <li>
                            <router-link class="navbar-brand" to="/review">
                                고객 후기
                            </router-link>
                        </li>
                        <li v-if="currentUser">
                            <a class="navbar-brand" href @click.prevent="logOut()">
                                로그아웃
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


    </nav>


    <router-view/>

        <!-- 상단으로 이동하는 버튼 -->
    <!-- <div onclick="window.scrollTo(0, 0);">▲TOP</div> 에서 아래와 같이 변경-->
    <div class="topBtn" onclick="window.scrollTo(0, 0);">
      <!--      <i class="bi bi-arrow-up-circle"></i>-->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        fill="currentColor"
        class="bi bi-arrow-up-circle"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
        />
      </svg>
    </div>

  </div>

        <FooterCom class="blank" />
</div>
</template>

<script>

/* eslint-disable */

// initMoving(document.getElementById("MU_quick_left1"),10, 0, 1175);

$(window).scroll(function () {
  if ($(this).scrollTop() > 300) {
    $(".topBtn").show();
  } else {
    $(".topBtn").hide();
  }
});
// 상단으로 이동하는 버튼 클릭이벤트
$(".topBtn").click(function () {
  $("html, body").animate({ scrollTop: 0 }, 400);
  return false;
});

import FooterCom from "@/components/home/FooterCom";
import start from "@/assets/js/scripts"

export default {
    components: {
        FooterCom,
    },
    data() {
    return {
      homes: {
        intro : "기업소개",
        rental : "렌탈이란?"
      },

    };
  },
  mounted() {
    start();
  },
  computed: {
    currentUser() {
      //공유저장소의 전역변수(공유변수 : user)
      //자동으로 로그인이 되었으면 loggedIn = true, user = user
      //아니면 loggedIn = false, user = null
      return this.$store.state.auth.user;
      // return true;
    }
  },
  methods: {
    logOut() {
      //dispatch 호줄하는 메서드 : actions에 있는 메서드를 호출함
      this.$store.dispatch("auth/logout")
      //로그아웃 후 이동 할 페이지 지정
      this.$router.push("/login")
    }
  },
}
</script>

<style scoped>
#app {
  height: 100%;
}
/* 상단 이동 버튼 클릭시 상단으로 움직일 때 부드러운 뷰 애니메이션 효과 추가 */
html {
  scroll-behavior: smooth;
}

/* 상단 이동 버튼 클릭시 애니메이션 효과 줄인 사람들을 위해 @media auto 추가  */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* 상단으로 이동하는 버튼 */
.topBtn {
  /* display: block 에서 none 으로 변경 */
  display: none;
  position: fixed;
  z-index: 999;
  cursor: pointer;
  /*margin: 30px 100px;*/
  bottom: 3vw;
  right: 5vw;
  color: #777777;
  background: transparent;
}

/* topBtn hover event */
.topBtn:hover {
  color: rgb(36, 36, 36);
}

@media all and (max-width: 768px) {
  .bi-arrow-up-circle {
    width: 40px;
    height: 40px;
    margin: 2vw;
  }
}

.navbar-collapse {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 3rem;
}

.blank {
    margin-top: 20%;
}
/* #navbarResponsive {
    margin-bottom: 2rem;
} */
</style>
