import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: "/home",
    name: 'home',
    component: () => import('@/views/HomeView')
},
  {
    path: '/about',
    name: 'About',
    component: () => import('../components/About.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../components/notice.vue')
  },
  {
    path: '/b2babout',
    name: 'b2bAbout',
    component: () => import('../components/b2bAbout.vue')
  },
  {
    path: '/comprent',
    name: 'compRent',
    component: () => import('../components/compRent.vue')
  },
  {
    path: '/introprod',
    name: 'introProd',
    component: () => import('../components/dropdown/introProd.vue')
  },
  {
    path: '/targetprod',
    name: 'targetProd',
    component: () => import('../components/dropdown/targetProd.vue')
  },
  {
    path: '/auditproc',
    name: 'auditProc',
    component: () => import('../components/dropdown/auditProc.vue')
  },
  {
    path: '/limit',
    name: 'limit',
    component: () => import('../components/dropdown/limit.vue')
  },
  {
    path: '/b2brent',
    name: 'b2bRent',
    component: () => import('../components/b2bRent.vue')
  },
  {
    path: '/auditguide',
    name: 'auditguide',
    component: () => import('../components/dropdown/auditguide.vue')
  },
  {
    path: '/seoulguarantee',
    name: 'seoulguarantee',
    component: () => import('../components/dropdown/seconddrop/seoulguarantee.vue')
  },
  {
    path: '/seoulgrtupload',
    name: 'seoulgrtupload',
    component: () => import('../components/dropdown/seconddrop/seoulgrtupload.vue')
  },
  {
    path: '/oneclicknotice',
    name: 'oneclicknotice',
    component: () => import('../components/dropdown/seconddrop/oneclicknotice.vue')
  },
  {
    path: '/purchcomp',
    name: 'purchcomp',
    component: () => import('../components/dropdown/purchcomp.vue')
  },
  {
    path: '/machinerant',
    name: 'machinerant',
    component: () => import('../components/dropdown/machinerant.vue')
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('../components/review.vue')
  },
  {
    path: '/signup',
    name: 'SignUpCom',
    component: () => import('../components/SignUpCom.vue')
  },
  {
    path: '/login',
    name: 'LoginCom',
    component: () => import('../components/LoginCom.vue')
  },
  {
    path: '/add_notice',
    name: 'add-add_notice',
    component: () => import('../components/addoredit/AddNotice.vue')
  },
  {
      path: '/edit_notice/:id',
      name: 'edit_notice',
      component: () => import('../components/addoredit/EditNotice.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
